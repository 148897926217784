import { Link } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import "./Card.css"

const Card = ({ image, title, link }) => (
  <li>
    <Link to={link}>
      <img src={image} alt="img" />
      <h2>{title}</h2>
    </Link>
  </li>
)

Card.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default Card
