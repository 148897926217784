import React from "react"
import PropTypes from "prop-types"

import "./CardList.css"

const CardList = ({ children }) => <ul>{children}</ul>

CardList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardList
