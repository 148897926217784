import React from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Card from "../components/Card/Card"
import CardList from "../components/CardList/CardList"

const Index = ({ pageContext }) => (
  <Layout>
    <Seo title="Home" />
    <CardList>
      {pageContext.cards.map((card, index) => (
        <Card
          index={index}
          image={card.thumbnail}
          title={card.title}
          link={card.link}
        />
      ))}
    </CardList>
  </Layout>
)

export default Index
